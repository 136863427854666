
import { defineComponent, inject } from 'vue'
import { debounce } from 'debounce'
import { DEBOUNCE_INTERVAL } from '@/config'

import { GetProductsParams, Product } from '@/data/product/types'
import { ProductRepositoryKey } from '@/data/injectables'

import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import ProductListItem from '@/views/product/ProductListItem.vue'
import SearchNavBar from '@/components/navbar/SearchNavBar.vue'
import ActionBar from '@/components/ActionBar.vue'
import ActionButton from '@/components/ActionButton.vue'
import ListWrapper from '@/components/ListWrapper.vue'

export default defineComponent({
  components: {
    ProductListItem,
    SearchNavBar,
    ActionBar,
    ActionButton,
    ListWrapper,
  },
  setup: () => {
    const productRepository = inject(ProductRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return { productRepository, handleError }
  },
  data() {
    return {
      loading: false,
      error: false,
      searchText: '',
      products: [] as Product[],
    }
  },
  watch: {
    searchText() {
      this.loading = true
      this.loadProductsDebounced()
    },
  },
  created() {
    this.loadProducts()
  },
  methods: {
    handleEdit(productId: string): void {
      this.$router.push({ path: `/products/${productId}/edit` })
    },
    handleViewPrices(): void {
      window.open('https://precos.aguasdaolaria.com.br', '_blank')
    },
    handleCreate(): void {
      this.$router.push({ path: '/products/create' })
    },
    async loadProducts(): Promise<void> {
      try {
        this.error = false
        this.loading = true
        const params: GetProductsParams = {
          name: this.searchText || undefined,
        }
        const products = await this.productRepository?.getProducts(params)
        this.products = products || []
      } catch (e) {
        this.error = true
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    loadProductsDebounced: debounce(function (this: {
      loadProducts: () => void
    }) {
      this.loadProducts()
    },
    DEBOUNCE_INTERVAL),
  },
})
