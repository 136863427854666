
import { Product } from '@/data/product/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  computed: {
    statusStyle(): string {
      return this.product.inactive ? 'status--inactive' : 'status--active'
    },
  },
})
