
import { defineComponent, PropType } from 'vue'

import ListEmpty from '@/components/ListEmpty.vue'
import ListError from '@/components/ListError.vue'

export default defineComponent({
  components: { ListEmpty, ListError },
  props: {
    records: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMsg: {
      type: String as PropType<string>,
      default: 'Não foi possível carregar os dados.',
    },
    reloadCb: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
  },
})
